import debounce from '/jsModules/build/helpers/debounce.js';

const SEARCHING_BODY_CLASSNAME = 'searching';

function search() {
    const searchInput = document.getElementById('js-search');
    const debouncedFetch = debounce((value) => {
        import("./searchBar/startSearching.js" + "?v=" + Math.floor(Date.now() / 100000))
            .then((module) => {
                module.startSearching(value);
            })
            .catch((err) => {
                console.error('ERROR:', err);
            });
    }, 200);

    // Function to handle focus
    function handleFocus() {
        const searchResults = document.getElementById('js-search-results');
        const searchCloseButton = document.getElementById('js-search-close-btn');

        function handleCloseByClick(e) {
            const targetId = e.target.id;

            if (
                    targetId === 'js-search-mobile-results' || 
                    targetId === 'js-search-results' || 
                    targetId === 'js-search-close-btn'
                ) {

                document.body.classList.remove(SEARCHING_BODY_CLASSNAME);
            }
        }

        searchResults.addEventListener('click', handleCloseByClick);
        searchCloseButton.addEventListener('click', handleCloseByClick);
        
        document.body.classList.add(SEARCHING_BODY_CLASSNAME);
        searchInput.addEventListener('input', (event) => {
            const value = event.target && event.target.value;
            debouncedFetch(value);
        });
    }
    
    // Add focus and blur event listeners to the input element
    searchInput.addEventListener('focus', handleFocus); 
};

search();

